import { Dispatch, SetStateAction, useState } from 'react';
import ChangePin from './ChangePin';
import ForgotPin from './ForgotPin';
import ChangePassword from './ChangePassword';
import SecurityQuestion from './SecurityQuestion';
import ChangeSecurityQuestion from './ChangeSecurityQuestion';
import Setup2FA from './Setup2FA';
import { Field, Label, Switch } from '@headlessui/react';
import { useToast } from 'components/Notifications/Toast';
import { executeSet2Fa } from 'api/settings/security';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import Loader from 'components/Notifications/Loader';
import { populateAccount } from 'store/account/accountSlice';
import { BiChevronRight } from 'react-icons/bi';

const Security = () => {
  const [state, setState] = useState<number | null>(null);
  const account = useAppSelector((state) => state.accountStore.account);
  const [enabled, setEnabled] = useState<boolean>(account?.two_fa ?? false);
  const [qrCode, setQrCode] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const token = useAppSelector((state) => state.accountStore.tokenStore);
  const userId = useAppSelector((state) => state.accountStore.account?.id);
  const dispatch = useAppDispatch();

  const { showToast } = useToast();

  // Function to handle verification logic (stub)
  const handleVerify = (code: string) => {
    console.log('Verification code:', code);
  };

  // Function to fetch and set up 2FA QR code
  const handleSetUp2fa = async () => {
    if (!userId) return;

    try {
      setIsLoading(true);
      const result = await executeSet2Fa(userId, token);
      if (!result.isSuccess) throw new Error(result.message);

      // Check if 2FA is enabled
      if (enabled) {
        setQrCode(result?.data?.data?.qr_code);
        dispatch(populateAccount({ two_fa: true }));
        showToast('2FA enabled successfully', 'success');
      } else {
        setQrCode(null);
        showToast('2FA disabled successfully', 'success');
        dispatch(populateAccount({ two_fa: false }));
      }
    } catch (error: any) {
      showToast(error.message, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  console.log(account?.two_fa, enabled);

  // Security settings options
  const securitySettings = [
    { name: 'Change PIN', component: <ChangePin /> },
    { name: 'Forgot PIN', component: <ForgotPin /> },
    { name: 'Change Password', component: <ChangePassword /> },
    { name: 'Security Question', component: <SecurityQuestion /> },
    { name: 'Change Security Question', component: <ChangeSecurityQuestion /> },
    {
      name: 'Setup 2FA',
      component: (
        <div className="flex flex-col gap-6">
          <Toggle2FA
            enabled={enabled}
            setEnabled={async (value) => {
              setEnabled(value);
              await handleSetUp2fa();
            }}
          />
          {isLoading && !enabled && !qrCode ? (
            <Loader />
          ) : (
            <Setup2FA qrCode={qrCode} onVerify={handleVerify} />
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="flex gap-48">
      <div className="flex-shrink-0 w-80">
        <h2 className="text-xl font-semibold mb-6">Security</h2>
        <div className="space-y-4">
          {securitySettings.map((item, idx) => (
            <div
              key={idx}
              className="flex items-center justify-between py-4 border-b border-gray-300 cursor-pointer"
              onClick={() => setState(idx)}
            >
              <span className="text-gray-900 text-sm">{item.name}</span>
              <BiChevronRight className="text-gray-700 h-6 w-6" />
            </div>
          ))}
        </div>
      </div>
      <div className="flex-grow">{securitySettings[state ?? 0]?.component}</div>
    </div>
  );
};

export default Security;

// Toggle2FA component to enable/disable 2FA
interface Props {
  enabled: boolean;
  setEnabled: Dispatch<SetStateAction<boolean>>;
}

export const Toggle2FA: React.FC<Props> = ({ enabled, setEnabled }) => {
  return (
    <Field>
      <Label className="mb-2 mr-4">Enable 2FA</Label>
      <Switch
        checked={enabled}
        onChange={setEnabled} // Directly call the `setEnabled` prop
        className={`${
          enabled ? 'bg-blue-600' : 'bg-gray-200'
        } inline-flex h-6 w-11 items-center rounded-full transition`}
      >
        <span
          className={`${
            enabled ? 'translate-x-6' : 'translate-x-1'
          } inline-block h-4 w-4 transform rounded-full bg-white transition`}
        />
      </Switch>
    </Field>
  );
};
