import locker from 'assets/locker.png';
import man from 'assets/man-shout.png';
import { DocumentCopyIcon } from 'components/icons';
import { useState } from 'react';

const ReferAndEarn = () => {
  const [copied, setCopied] = useState(false);
  const referralCode = 'CODE_HERE'; // Replace with your actual referral code

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(referralCode);
      setCopied(true);

      // Reset the "Copied" state after 2 seconds
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    } catch (err) {
      console.error('Failed to copy text:', err);
    }
  };

  return (
    <div className="px-6 py-5 bg-[#F5FFF8] h-full">
      <h1 className="text-[20px] font-medium text-center mb-6 text-primary">
        Refer and Earn
      </h1>
      <div className="flex items-center justify-end text-center">
        <div className="flex flex-col items-center">
          <img src={locker} alt="Locker" className="mb-4" />
          <span
            className="cursor-pointer relative"
            onClick={handleCopy}
            title="Click to copy"
          >
            <DocumentCopyIcon />
            {copied && (
              <span className="absolute -top-8 left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs py-1 px-2 rounded whitespace-nowrap">
                Copied!
              </span>
            )}
          </span>
          <h2 className="text-6 font-semibold">{referralCode}</h2>
        </div>
        <img src={man} alt="Man" className="ml-4" />
      </div>
    </div>
  );
};

export default ReferAndEarn;
