import React from 'react';
import FlagsSelect from 'components/common/FlagSelect';
import { UseFormRegister, FieldErrors } from 'react-hook-form';
import { registrationInputs } from 'utils/data';
import { Input } from 'components/common/Form';

interface DeviceInfo {
  channel: string;
  device_identifier: string;
  device_os: string;
  device_model: string;
  ip_address: string;
}

interface RegistrationData extends DeviceInfo {
  first_name: string;
  last_name: string;
  middle_name: string;
  phone_number: string;
  country_id: string;
  email: string;
  membership_type: string;
  dob: string;
  bvn: string;
  password: string;
  confirm_password: string;
  country_code: string;
}

interface FormProps {
  register: UseFormRegister<RegistrationData>;
  errors: FieldErrors<RegistrationData>;
}

const First: React.FC<FormProps> = ({ register, errors }) => {
  return (
    <>
      <div className="flex justify-between items-center mb-14">
        <h2 className="text-2xl font-semibold">Sign Up</h2>
        <FlagsSelect />
      </div>
      {registrationInputs().map((input, idx) => (
        <div key={idx} className="mb-4">
          <Input
            name={input.name}
            placeholder={input.placeholder}
            color={true}
            label={input.label}
            type={input.type}
            register={register}
            pattern={
              input.type === 'text'
                ? /^[A-Za-z\s]+$/ // Only letters and spaces allowed
                : undefined
            }
            required={input.isRequired}
          />
          {errors[input.name as keyof RegistrationData] && (
            <p className="text-red-600 text-sm mt-1">
              {errors[input.name as keyof RegistrationData]?.message ||
                `Enter a valid ${input.label}`}
            </p>
          )}
        </div>
      ))}
    </>
  );
};

export default First;
