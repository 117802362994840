import { Listbox, Menu, Switch } from '@headlessui/react';
import React, { useState } from 'react';
import { BiLoaderCircle } from 'react-icons/bi';
import DatePicker from 'react-datepicker';
import { Link as ReactLink } from 'react-router-dom';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { FiArrowRight } from 'react-icons/fi';
import { HiEye, HiEyeOff } from 'react-icons/hi'; // Import eye icons

export const Input: React.FC<InputProps> = ({
  label,
  name,
  type,
  color,
  placeholder,
  register,
  onChange,
  required,
  disabled,
  maxLength,
  value,
  defaultValue,
  errors,
  pattern,
  readOnly,
  className,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const hasError = errors?.[name];

  // Determine the input type based on password visibility
  const inputType =
    type === 'password' ? (showPassword ? 'text' : 'password') : type;

  return (
    <div className="text-sm w-full">
      <label
        className={`${
          color ? 'text-[#585757] text-sm' : 'text-white font-semibold'
        }`}
      >
        {label}
        {required && <span className="text-red-500"> *</span>}
      </label>
      <div className="relative">
        <input
          name={name}
          type={inputType}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          disabled={disabled}
          readOnly={readOnly}
          maxLength={maxLength}
          defaultValue={defaultValue}
          className={`w-full bg-white text-sm mt-3 p-4 border focus:outline-none ${
            hasError
              ? 'border-red-500'
              : color
                ? 'border-border font-light'
                : 'border-white text-white'
          } rounded-lg focus:border ${
            hasError ? 'focus:border-red-500' : 'focus:border-subMain'
          } ${disabled ? 'cursor-not-allowed opacity-50' : ''}
          ${readOnly ? 'bg-gray-50 cursor-not-allowed' : ''}
          ${className || ''}`}
          {...register(name, {
            required: required ? `${label} is required` : false,
            pattern: pattern
              ? { value: pattern, message: `Invalid ${label}` }
              : undefined,
          })}
        />
        {type === 'password' && (
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            {!showPassword ? (
              <HiEyeOff className="w-5 h-5" />
            ) : (
              <HiEye className="w-5 h-5" />
            )}
          </button>
        )}
      </div>
      {hasError && (
        <p className="text-red-500 text-xs mt-1">{errors[name]?.message}</p>
      )}
    </div>
  );
};

export const Button: React.FC<ButtonProps> = ({
  label,
  onClick,
  loading,
  extraClasses,
  outline,
  disabled,
}) => {
  return (
    <button
      disabled={loading || disabled}
      onClick={onClick}
      className={`w-full mt-[40px] flex-rows gap-4 hover:opacity-80 transition 
        ${
          outline
            ? 'text-primary border border-primary'
            : 'text-white bg-primary'
        } 
        text-sm font-medium px-2 py-4 rounded 
        ${extraClasses || ''} 
        ${disabled ? 'opacity-50 cursor-not-allowed' : 'opacity-100'}`}
    >
      {loading ? (
        <BiLoaderCircle className="animate-spin text-white text-2xl" />
      ) : (
        <>{label}</>
      )}
    </button>
  );
};

// select

export const MenuSelect: React.FC<MenuSelectProps> = ({
  children,
  datas,
  item: data,
}) => {
  return (
    <div className="text-sm w-full relative">
      <Menu>
        <Menu.Button>{children}</Menu.Button>
        <Menu.Items className="flex flex-col z-50 gap-4 absolute left-0  bg-white rounded-md shadow-lg py-4 px-6 ring-1 ring-border focus:outline-none">
          {datas.map((item, index) => (
            <button
              onClick={() => item.onClick(data)}
              key={index}
              className={`flex gap-4 items-center hover:text-subMain`}
            >
              {item.icon && <item.icon className="text-md text-subMain" />}
              {item.title}
            </button>
          ))}
        </Menu.Items>
      </Menu>
    </div>
  );
};

// select 2

export const Select: React.FC<SelectProps> = ({
  children,
  selected,
  setSelected,
  datas,
  label,
  required,
}) => {
  return (
    <div className="text-sm relative w-full">
      <label className="text-[#585757] text-base flex items-center">
        {label}
        {required && <span className="text-red-500 ml-1"> *</span>}
      </label>
      <div className="w-full mt-4">
        <Listbox value={selected} onChange={setSelected}>
          <Listbox.Button className="w-full">{children}</Listbox.Button>
          <Listbox.Options className="flex flex-col gap-4 top-10 z-50 absolute left-0 w-full bg-white rounded-md shadow-lg py-4 px-6 ring-1 ring-border focus:outline-none max-h-60 overflow-y-auto">
            {datas?.map((person, idx) => (
              <Listbox.Option
                className="cursor-pointer text-xs hover:text-subMain"
                key={idx}
                value={person}
                disabled={person.unavailable}
              >
                {person.name}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Listbox>
      </div>
    </div>
  );
};

// switch

export const Switchi: React.FC<SwitchiProps> = ({ checked, onChange }) => {
  return (
    <Switch
      checked={checked}
      onChange={onChange}
      className={`${checked ? 'bg-subMain' : 'bg-border'}
        relative inline-flex p-[2px] w-12 cursor-pointer rounded-full transitions`}
    >
      <span
        aria-hidden="true"
        className={`${checked ? 'translate-x-5' : 'translate-x-0'}
          pointer-events-none inline-block h-6 w-6 transform rounded-full bg-white shadow-lg  transitions`}
      />
    </Switch>
  );
};

// textarea

export const Textarea: React.FC<TextareaProps> = ({
  label,
  name,
  register,
  placeholder,
  rows,
  color,
}) => {
  return (
    <div className="text-sm w-full">
      <label className={'text-black text-sm'}>{label}</label>
      <textarea
        name={name}
        rows={rows}
        // {...register}
        placeholder={placeholder}
        className={`focus:border-subMain w-full bg-transparent text-sm mt-3 p-4 border ${
          color ? 'border-border font-light' : 'border-white text-white'
        } rounded-lg focus:border focus:border-subMain`}
        {...register(name, { required: `${label} is required` })}

        // {...control.register("answer", {
        //   required: "Please enter an answer",
        // })}
      />
    </div>
  );
};

// date picker

export const DatePickerComp: React.FC<DatePickerCompProps> = ({
  label,
  startDate,
  onChange,
}) => {
  return (
    <div className="text-sm w-full">
      <label className={'text-black text-sm'}>{label}</label>
      <DatePicker
        selected={startDate}
        onChange={onChange}
        className="w-full bg-transparent text-sm mt-3 p-4 border border-border font-light rounded-lg focus:border focus:border-subMain"
      />
    </div>
  );
};

// time picker

export const TimePickerComp: React.FC<TimePickerCompProps> = ({
  label,
  startDate,
  onChange,
}) => {
  return (
    <div className="text-sm w-full">
      <label className={'text-black text-sm'}>{label}</label>
      <DatePicker
        selected={startDate}
        onChange={onChange}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={30}
        timeCaption="Time"
        dateFormat="h:mm aa"
        className="w-full bg-transparent text-sm mt-3 p-4 border border-border font-light rounded-lg focus:border focus:border-subMain"
      />
    </div>
  );
};

// checkbox

export const Checkbox: React.FC<CheckboxProps> = ({
  label,
  name,
  onChange,
  checked,
}) => {
  return (
    <div className="text-sm w-full flex flex-row items-center">
      <label className="flex-colo cursor-pointer relative">
        <input
          type="checkbox"
          name={name}
          checked={checked}
          onChange={onChange}
          className="absolute opacity-0 w-0 h-0"
        />
        <span
          className={` border rounded  w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2 ${
            checked ? 'border-primary bg-primary' : 'border-gray-300 bg-white'
          }`}
        >
          <FaCheck
            className={`text-[10px] ${checked ? 'block text-white' : 'hidden'}`}
          />
        </span>
      </label>

      {label && <p className={'text-black text-xs ml-2'}>{label}</p>}
    </div>
  );
};

// from to date picker

export const FromToDate: React.FC<FromToDateProps> = ({
  label,
  startDate,
  onChange,
  endDate,
  bg,
}) => {
  return (
    <div className="text-sm w-full flex flex-col gap-2">
      {label && <label className={'text-black text-sm'}>{label}</label>}
      <DatePicker
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onChange={(dates: [Date | null, Date | null]) => onChange(dates)}
        className={`w-full ${
          bg ? bg : 'bg-transparent'
        } text-xs px-4 h-14 border border-border text-main font-normal rounded-lg focus:border focus:border-subMain`}
      />
    </div>
  );
};

export const FormFooter = () => {
  return (
    <div className="text-center w-[100%">
      {/* <p className="text-sm text-[#949596]">
        By Signing up, you agree to our{' '}
        <a className="text-primary ">Terms of Use</a> <br /> and{' '}
        <a className="text-primary">Privacy Policy</a>
      </p> */}
      <p className="text-sm text-gray-600 mt-7">
        If you have an account{' '}
        <ReactLink
          to={'/login'}
          className="inline-flex items-center text-primary"
        >
          Log in{' '}
          <span className="ml-1">
            <FiArrowRight size={12} />
          </span>
        </ReactLink>
      </p>
    </div>
  );
};

interface FormNavigatorProps {
  setState: React.Dispatch<React.SetStateAction<number>>;
  state: number;
  closeDrawer: () => void;
}

export const FormNavigator: React.FC<FormNavigatorProps> = ({
  setState,
  state,
  closeDrawer,
}) => {
  return (
    <div className="flex justify-between w-full mb-2">
      <span onClick={() => setState(state)} className="cursor-pointer">
        ←
      </span>
      <FaTimes className="cursor-pointer" onClick={closeDrawer} />
    </div>
  );
};
