import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="max-w-4xl mx-auto p-8">
      {/* Header */}
      <h1 className="text-2xl font-semibold mb-8">Privacy Policy</h1>

      {/* Introduction Section */}
      <section className="mb-8">
        <p className="text-gray-700 leading-relaxed">
          The following Privacy Policy applies to our data collection and use
          practices relating to your use of WorldHero LLC&apos;s financial
          services, our website and our other products and services
          (collectively, &quot;WorldHero LLC Services&quot;). As a WorldHero LLC
          client, service user or visitor, you agree to this Privacy Policy and
          any further modifications made to this Privacy Policy. The terms
          &quot;WorldHero LLC&quot;, &quot;we&quot;, &quot;our&quot;, or
          &quot;us&quot; refer to WorldHero LLCs or the WorldHero LLC entity
          responsible for the collection and use of personal data under this
          Privacy Policy. It may vary depending on your country.
        </p>
      </section>

      {/* Written Agreements Section */}
      <section className="mb-8">
        <p className="text-gray-700 leading-relaxed">
          WorldHero LLC may enter into written product or service agreements
          with business customers that contain specific provisions related to
          the confidentiality, security or other handling of information. If any
          provisions of a written product or service agreement differ from or
          conflict with the provisions outlined in this Privacy Policy, the
          terms of the written agreement will apply. Otherwise, the terms of
          this Policy apply.
        </p>
      </section>

      {/* Policy Coverage Section */}
      <section className="mb-8">
        <p className="text-gray-700 leading-relaxed mb-4">
          The Privacy Policy applies solely to information collected by this
          website and its related services, and will notify you of the
          following:
        </p>
        <ul className="list-disc pl-8 space-y-2 text-gray-700">
          <li>
            Information Collection, Use and Sharing: This outlines what personal
            and non-personal information is collected from you through the
            website, how it is used and with whom it may be shared.
          </li>
          <li>
            Cookies and Your Control Over Them: This explains the use of cookies
            on the website and how you can control them.
          </li>
          <li>
            Your Control Over Your Data: This describes choices available to you
            regarding the use of your data.
          </li>
          <li>
            Your Access to Your Data: This outlines how you may access your
            data.
          </li>
          <li>
            Securing Your Data: This provides information on security measures
            in place to prevent the misuse of your information.
          </li>
          <li>
            Contacting Us: This explains how can contact us regarding any
            inaccuracies in the information.
          </li>
        </ul>
      </section>

      {/* Definitions Section */}
      <section className="mb-8">
        <p className="text-gray-700 leading-relaxed mb-6">
          &quot;Personal Data&quot; means any information that relates to an
          identified or identifiable individual, and can include information
          about how you engage with our Services (e.g., device information, IP
          address).
        </p>

        <p className="text-gray-700 leading-relaxed mb-8">
          &quot;Services&quot; means the products and services that WorldHero
          LLC indicates are covered by this Policy, which may include WorldHero
          LLC-provided devices and applications. Our &quot;Business
          Services&quot; are Services provided by WorldHero LLC to entities
          (&quot;Business Users&quot;) who directly and indirectly provide us
          with &quot;End Customer Personal Data&quot; in connection with those
          Business Users&apos; own business and activities. Our &quot;End User
          Services&quot; are those Services which WorldHero LLC directs to
          individuals (rather than entities) so that those individuals do
          business directly with WorldHero LLC. &quot;Sites&quot; means
          StarkAssociates.com and the other websites that WorldHero LLC
          indicates are covered by this Policy. Collectively, we refer to Sites,
          Business Services and End User Services as &quot;Services&quot;.
        </p>

        <p className="text-gray-700 leading-relaxed mb-8">
          Depending on the activity, WorldHero LLC acts as a &quot;data
          controller&quot; or &quot;data processor&quot; and for more
          information about this and on the WorldHero LLC entity that is
          responsible under this Policy, see our terms and conditions.
        </p>

        <p className="text-gray-700 leading-relaxed">
          This privacy policy will provide information on the following:
        </p>
        <ol className="list-decimal pl-8 space-y-2 text-gray-700 mt-4">
          <li>Personal data that we collect and how we use and share it</li>
          <li>More ways we collect, use and share personal data</li>
          <li>Legal basis for processing data</li>
        </ol>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
