import React from 'react';

const TermsOfService = () => {
  return (
    <div className="max-w-4xl mx-auto p-8">
      {/* Header */}
      <h1 className="text-2xl font-semibold mb-8">Terms of Service</h1>

      {/* Introduction Section */}
      <section className="mb-12">
        <p className="text-gray-700 leading-relaxed">
          Please read carefully the provisions of these Terms of Use (the
          &ldquo;Agreement&rdquo; or &ldquo;Terms of Use&rdquo;). It is a legal
          agreement between you and WorldHero LLC (&ldquo;WorldHero LLC&rdquo;,
          &ldquo;we&rdquo;, or &ldquo;us&rdquo;) regarding the use of our online
          and/or mobile services, website, and software provided by us
          (collectively known as the &ldquo;WorldHero LLC Service&rdquo;). By
          accessing or using the WorldHero LLC Service, you agree that you have
          read, understood, and agree to be bound by these Terms of Use, whether
          or not you are a registered user of the WorldHero LLC Service.
        </p>
      </section>

      {/* Amendment Rights Section */}
      <section className="mb-12">
        <p className="text-gray-700 leading-relaxed">
          We reserve the right to amend this Agreement at any time by notifying
          you as provided in this Agreement, provided that no notice shall be
          required or given for non-substantive changes to the Agreement. If we
          substantively amend This Agreement, we will give you at least seven
          (7) days&rsquo; notice before the changes take effect, during which
          period of time you may reject the changes by terminating your account.
          Your continued use of the WorldHero LLC Service after any such change
          takes effect constitutes your acceptance of the new Terms of Use. If
          you do not agree to any of these terms or any future Terms of Use,
          your only remedy shall be to not use or access (or continue to access)
          the WorldHero LLC Service. This Agreement applies to all visitors,
          users, and others who access the WorldHero LLC Service
          (&ldquo;Users&rdquo;).
        </p>
      </section>

      {/* Use of Service Section */}
      <section className="mb-12">
        <h2 className="text-xl font-semibold mb-6">USE OF OUR SERVICE</h2>

        <div className="mb-8">
          <h3 className="text-lg font-semibold mb-4">ELIGIBILITY</h3>
          <p className="text-gray-700 leading-relaxed">
            You may use the WorldHero LLC Service only if you can form a binding
            contract with WorldHero LLC, and only in compliance with this
            Agreement and all applicable local, state, national, and
            international laws, rules and regulations. If you are under 18 of
            age, your parent or guardian must enter into this Agreement on your
            behalf, and will be responsible for your use of and access to the
            WorldHero LLC Service. Any use or access to the WorldHero LLC
            Service by anyone under 13 is prohibited and in violation of this
            Agreement. If you are under 13, please do not attempt to register
            for the WorldHero LLC Service or send any information about yourself
            to us, including your name, address, telephone number, or email
            address. If we learn that we have collected personal information
            from anyone under age 13 without verification of parental consent,
            we will delete that information as quickly as possible. WorldHero
            LLC reserves all rights not expressly granted under this Agreement.
            Any attempt by you to transfer any of the rights, duties or
            obligations hereunder, except as expressly provided for in this
            Agreement, is void.
          </p>
        </div>

        <div className="mb-8">
          <h3 className="text-lg font-semibold mb-4">WORLDHERO LLC ACCOUNT</h3>
          <p className="text-gray-700 leading-relaxed mb-6">
            You must create an account in order to gain access to the services
            and functionality that we may establish and maintain from time to
            time and at our sole discretion. You may not use another
            User&rsquo;s account without permission.
          </p>
          <p className="text-gray-700 leading-relaxed mb-6">
            You are solely responsible for the activity that occurs on your
            account, and you must keep your account password secure. You must
            notify WorldHero LLC immediately of any breach of security or
            unauthorised use of your account. WorldHero LLC will not be liable
            for any losses caused by any unauthorised use of your account.
          </p>
          <p className="text-gray-700 leading-relaxed mb-6">
            The WorldHero LLC Service is available through the Google Sign-In
            service, which requires an active Google account. By accessing the
            WorldHero LLC Service through Google Sign-In you represent and
            warrant that you have read and agreed to be bound by all applicable
            Google policies and will act in accordance with those policies, in
            addition to your obligations under this Agreement. If you access the
            WorldHero LLC Service through Google Sign in, WorldHero LLC may
            require that your WorldHero LLC user ID be the same as your username
            for Google.
          </p>
          <p className="text-gray-700 leading-relaxed">
            If you sign into WorldHero LLC through Google Sign in, you will
            provide your Google account credentials to WorldHero LLC, and you
            are consenting to have the information in that account transmitted
            into your WorldHero LLC account, and you agree that you shall only
            use Google accounts owned by you, and not by any other person or
            entity. By providing WorldHero LLC your email address, you consent
            to us using that email address to send you WorldHero LLC
            Service-related notices, including any notices required by law, in
            lieu of communication by snail mail. We may also use your email
            address to send you other messages, such as changes to features of
            the WorldHero LLC Service and special offers. If you do not want to
            receive such email messages, you may opt out by sending us a request
            at optout@worldherollc.com. Opting out may prevent you from
            receiving email messages regarding updates, improvements, or offers.
            Please note that if you do not want to receive legal notices from
            us, including without limitation a notice that this Agreement has
            changed, those legal notices will still govern your use of the
            WorldHero LLC Service. We will post legal notices to the WorldHero
            LLC Service, and you are responsible for reviewing such legal
            notices for changes. Please see our Privacy Policy for more details.
          </p>
        </div>
      </section>
    </div>
  );
};

export default TermsOfService;
