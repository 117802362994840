import React, { useState, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import AuthLayout from 'layouts/Auth';
import { Button } from 'components/common/Form';
import { useToast } from 'components/Notifications/Toast';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { STEPS, updateLevel } from 'store/registration/registerSlice';
import { executeSetPinCode } from 'api/settings/users';
import authBg from 'assets/form-bg-three.png';

// Define interfaces for the components
interface PinBoxProps {
  length?: number;
  value?: string;
  onChange: (value: string) => void;
  error?: boolean;
}

interface SecureAccountProps {
  setActiveStep: (value: number | ((prevValue: number) => number)) => void;
}

interface FormValues {
  pin: string;
  confirm: string;
}

interface UserProfile {
  pin?: number | string;
  confirm?: number;
}

// PIN Input Box Component
const PinBox: React.FC<PinBoxProps> = ({
  length = 4,
  value = '',
  onChange,
  error,
}) => {
  const [pins, setPins] = useState<string[]>(Array(length).fill(''));
  const [visiblePins, setVisiblePins] = useState<boolean[]>(
    Array(length).fill(false),
  );
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    if (value) {
      const pinArray = value.split('').slice(0, length);
      setPins([...pinArray, ...Array(length - pinArray.length).fill('')]);
    }
  }, [value, length]);

  const handleChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const val = e.target.value;
    if (val === '' || /^\d+$/.test(val)) {
      const newPins = [...pins];
      const digitValue = val.slice(-1);
      newPins[index] = digitValue;
      setPins(newPins);

      // Show the digit temporarily
      const newVisiblePins = [...visiblePins];
      newVisiblePins[index] = true;
      setVisiblePins(newVisiblePins);

      // Hide the digit after a short delay
      setTimeout(() => {
        setVisiblePins((prev) => {
          const updated = [...prev];
          updated[index] = false;
          return updated;
        });
      }, 300);

      const pinValue = newPins.join('');
      onChange(pinValue);

      if (val && index < length - 1 && inputRefs.current[index + 1]) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleKeyDown = (
    index: number,
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (
      e.key === 'Backspace' &&
      !pins[index] &&
      index > 0 &&
      inputRefs.current[index - 1]
    ) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text').slice(0, length);
    if (/^\d+$/.test(pastedData)) {
      const newPins = [...pins];
      pastedData.split('').forEach((char, index) => {
        if (index < length) newPins[index] = char;
      });
      setPins(newPins);

      // Show all pasted digits temporarily
      setVisiblePins(Array(length).fill(true));

      // Hide all digits after a short delay
      setTimeout(() => {
        setVisiblePins(Array(length).fill(false));
      }, 300);

      onChange(newPins.join(''));
    }
  };

  return (
    <div className="flex gap-4 justify-center">
      {pins.map((pin, index) => (
        <input
          key={index}
          type="text"
          inputMode="numeric"
          maxLength={1}
          value={visiblePins[index] ? pin : pin ? '•' : ''}
          ref={(el) => (inputRefs.current[index] = el)}
          onChange={(e) => handleChange(index, e)}
          onKeyDown={(e) => handleKeyDown(index, e)}
          onPaste={handlePaste}
          className={`w-12 h-12 text-center border ${
            error ? 'border-red-500' : 'border-gray-300'
          } rounded-lg text-lg focus:outline-none focus:border-blue-500`}
        />
      ))}
    </div>
  );
};

// Main SecureAccount Component
const SecureAccount: React.FC<SecureAccountProps> = ({ setActiveStep }) => {
  const {
    setValue,
    watch,
    trigger,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onSubmit',
    defaultValues: {
      pin: '',
      confirm: '',
    },
  });

  const token = useAppSelector((state) => state.accountStore.tokenStore);
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { showToast } = useToast();

  const pin = watch('pin');
  const confirm = watch('confirm');

  const handlePinChange = (value: string, field: keyof FormValues) => {
    setValue(field, value);
  };

  const onSubmit = async (data: FormValues) => {
    if (!(await trigger())) return false;
    try {
      if (data.pin !== data.confirm) throw new Error('The codes do not match');
      setIsLoading(true);

      const pinNumber = parseInt(data.pin, 10);

      if (isNaN(pinNumber)) {
        throw new Error('Invalid PIN format');
      }

      const payload: UserProfile = {
        pin: pinNumber.toString(),
      };

      const result = await executeSetPinCode(payload, token);
      if (!result.isSuccess) throw new Error(result.message);

      dispatch(updateLevel({ step: STEPS.ACTIVATE_PIN }));
      setActiveStep((prev) => prev + 1);
    } catch (error) {
      if (error instanceof Error) {
        showToast(error.message, 'error');
      } else {
        showToast('An unexpected error occurred', 'error');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthLayout background={true} backgroundImage={authBg}>
      <div className="space-y-4 mb-14">
        <h2 className="text-2xl font-semibold">Pin Code</h2>
        <p className="text-sm text-gray-500">
          Re-enter your transaction PIN to confirm
        </p>
      </div>
      <form className="space-y-8" onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">
            Input Pin Code
          </label>
          <PinBox
            value={pin}
            onChange={(value) => handlePinChange(value, 'pin')}
            error={Boolean(errors.pin)}
          />
        </div>
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">
            Confirm Pin Code
          </label>
          <PinBox
            value={confirm}
            onChange={(value) => handlePinChange(value, 'confirm')}
            error={Boolean(errors.confirm)}
          />
        </div>
        <Button loading={isLoading} label="Create PIN" />
      </form>
    </AuthLayout>
  );
};

export default SecureAccount;
