import { Button, Input, Checkbox } from 'components/common/Form';
import AuthLayout from 'layouts/Auth';
import React, { useEffect } from 'react';
import { BiLogInCircle } from 'react-icons/bi';
import { loginInputs } from 'utils/data';
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';
import { useAppDispatch } from 'store/hooks';
import { useToast } from 'components/Notifications/Toast';
import { useForm } from 'react-hook-form';
import { executeLogin } from 'api/auth';
import {
  populateAccount,
  populateToken,
  clearAccount,
} from 'store/account/accountSlice';
import useDisclosure from 'hooks/useDisclosure';
import { STEPS, updateLevel } from 'store/registration/registerSlice';

interface LoginData {
  email: string;
  password: string;
  channel: string;
  device_identifier: string;
  device_os: string;
  device_model: string;
  ip_address: string;
}

interface DeviceInfo {
  channel: string;
  device_identifier: string;
  device_os: string;
  device_model: string;
  ip_address: string;
}

const Login = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    trigger,
    getValues,
    register,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
  });
  const { showToast } = useToast();
  const [isChecked, setIsChecked] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const getDeviceInfo = async (): Promise<DeviceInfo> => {
    try {
      // Fetch IP address
      const ipResponse = await fetch('https://api.ipify.org?format=json');
      if (!ipResponse.ok) {
        throw new Error('Failed to fetch IP address');
      }
      const ipData = await ipResponse.json();

      // Detect device information
      const userAgent = navigator.userAgent;
      const isIOS = /iPhone|iPad|iPod/.test(userAgent);
      const isAndroid = /Android/.test(userAgent);

      // Retrieve or generate a device identifier
      let deviceId = localStorage.getItem('device_identifier');
      if (!deviceId) {
        deviceId = `PC-${crypto.randomUUID()}`; // Use crypto for a unique ID
        localStorage.setItem('device_identifier', deviceId);
      }

      const deviceInfo: DeviceInfo = {
        channel: 'web',
        device_identifier: deviceId,
        device_os: isIOS ? 'ios' : isAndroid ? 'android' : 'web',
        device_model: isIOS
          ? 'iPhone'
          : isAndroid
            ? 'Android Device'
            : 'Desktop',
        ip_address: ipData.ip,
      };

      return deviceInfo;
    } catch (error) {
      console.error('Error getting device info:', error);
      throw new Error('Unable to get device information');
    }
  };

  useEffect(() => {
    dispatch(clearAccount());
  }, [dispatch]);

  const handleLogin = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    try {
      e.preventDefault();
      if (!(await trigger())) return;
      onOpen();

      const deviceInfo = await getDeviceInfo();

      const payload: LoginData = {
        email: getValues('email').trim(),
        password: getValues('password').trim(),
        ...deviceInfo,
      };

      const result = await executeLogin(payload);
      console.log({ result });
      if (result.isSuccess === false) throw new Error(result.message);

      showToast(result.message, 'success');

      dispatch(populateToken(result.data.token));
      dispatch(
        populateAccount({
          ...result.data.account,
          email: payload.email,
          password: payload.password,
        }),
      );

      let destination: string;

      if (result.data.destination === '2fa') {
        destination = '/2fa';
      } else {
        if (result.data.account.pin === null) {
          dispatch(
            updateLevel({
              step: STEPS.SECURE_ACCOUNT,
            }),
          );
          destination = '/register';
        } else {
          destination = '/dashboard';
        }
      }

      navigate(destination);

      if (destination === '/dashboard') {
        dispatch(populateToken(result.data.token));
      }
    } catch (error: any) {
      console.log('ERROR:', error.message);
      showToast(error.message, 'error');
    } finally {
      onClose();
    }
  };

  return (
    <AuthLayout backgroundOverlay={`#F7F7F7`}>
      <div className="flex justify-between items-center mb-14">
        <h1 className="text-[32px] font-semibold">Log in</h1>
      </div>
      <div className="space-y-6">
        <form className="w-[375px] rounded-2xl mx-auto space-y-6">
          {loginInputs().map((input, idx) => (
            <div key={idx}>
              <Input
                label={input.label}
                type={input.type}
                color={true}
                placeholder={input.placeholder}
                register={register}
                required
                name={input.name}
              />
              {errors[input.name] && (
                <p className="text-red-500 text-xs mt-1">
                  {errors[input.name]?.message?.toString()}
                </p>
              )}
            </div>
          ))}
          <div className="flex w-full justify-end">
            <ReactLink to="/reset-password" className="text-red-500">
              Forgot Password?
            </ReactLink>
          </div>
        </form>
        <Checkbox
          name="read"
          label={'Keep me signed in'}
          checked={isChecked}
          onChange={() => setIsChecked((prev: boolean) => !prev)}
        />
        <Button
          label="Login"
          Icon={BiLogInCircle}
          onClick={handleLogin}
          loading={isOpen}
        />
        <div className="text-center">
          <ReactLink to={'/login'} className="inline-flex items-center">
            Trouble Logging in?
          </ReactLink>
          <p className="text-sm text-gray-500 mt-6">
            If you dont have an account
            <ReactLink
              to={'/register'}
              className="inline-flex items-center ml-2"
            >
              Sign up
              <span className="ml-1">
                <FaArrowRight size={12} />
              </span>
            </ReactLink>
          </p>
        </div>
      </div>
    </AuthLayout>
  );
};

export default Login;
