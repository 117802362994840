import React, { useEffect, useState } from 'react';
import useDisclosure from 'hooks/useDisclosure';
import { useAppSelector } from 'store/hooks';
import { executeGetAllCounterParties } from 'api/bank';
import { Button } from 'components/common/Form';
import CounterPartyVerificationModal from './CounterPartyVerificationModal';

interface Props {
  setState: (prev: any) => void;
  setSelectedCounterParty: (prev: any) => void;
  selectedCounterParty: string;
}

const Beneficiaries: React.FC<Props> = ({
  setState,
  setSelectedCounterParty,
  selectedCounterParty,
}) => {
  const token = useAppSelector((state) => state.accountStore.tokenStore);
  const userId = useAppSelector((state) => state.accountStore.account?.id);
  const [beneficiaries, setBeneficiaries] = useState<any[]>([]);
  const [selectedForVerification, setSelectedForVerification] = useState<
    string | null
  >(null);
  const {
    isOpen: isLoading,
    onOpen: openLoading,
    onClose: closeLoading,
  } = useDisclosure();

  const {
    isOpen: isVerificationModalOpen,
    onOpen: openVerificationModal,
    onClose: closeVerificationModal,
  } = useDisclosure();

  const fetchCounterParties = async () => {
    try {
      openLoading();
      const result = await executeGetAllCounterParties(token, userId!);
      if (!result.isSuccess) throw new Error(result.message);

      const parsedData = result.data.map((item: any) => {
        const beneficiary = JSON.parse(item.beneficiary_data);
        return {
          id: item.id,
          fullName: item.full_name || 'N/A',
          accountNumber: beneficiary.destination.accountNumber,
          bankCode: beneficiary.destination.bankCode,
          countryCode: item.country_code,
          createdAt: item.created_at,
          bankName: `Bank (${beneficiary.destination.bankCode})`,
          verificationStatus: item.verification_status,
        };
      });

      setBeneficiaries(parsedData);
    } catch (error: any) {
      console.error('Error fetching counter-parties:', error);
    } finally {
      closeLoading();
    }
  };

  const handleVerificationSuccess = () => {
    fetchCounterParties();
  };

  useEffect(() => {
    fetchCounterParties();
  }, []);

  const StatusTag: React.FC<{ status: string | null }> = ({ status }) => (
    <span
      className={`inline-flex items-center px-2.5 py-0.5 text-xs font-medium rounded-full ${
        status === 'passed'
          ? 'bg-green-50 text-green-700 ring-1 ring-green-600/20'
          : 'bg-yellow-50 text-yellow-800 ring-1 ring-yellow-600/20'
      }`}
    >
      {status === 'passed' ? 'Verified' : 'Pending Verification'}
    </span>
  );

  return (
    <div className="bg-gray-50 p-6 flex flex-col h-[600px]">
      {!beneficiaries.length && (
        <p>
          You dont have any beneficiaries yet, kindly click the button below to
          create one
        </p>
      )}
      <div className="space-y-3 flex-1 overflow-y-auto">
        {beneficiaries.map((beneficiary, index) => (
          <div
            key={index}
            className="flex items-center justify-between p-3.5 bg-white shadow-sm rounded-lg border border-gray-100 hover:border-gray-200 transition-colors duration-200"
          >
            <div className="flex items-center space-x-4 flex-grow">
              {beneficiary.verificationStatus === 'passed' ? (
                <input
                  type="checkbox"
                  className="h-4 w-4 rounded cursor-pointer border-gray-300 
                  checked:bg-primary checked:border-primary
                  focus:ring-2 focus:ring-primary focus:ring-offset-1
                  transition-colors duration-200"
                  checked={selectedCounterParty === beneficiary.id}
                  onChange={(e) => {
                    setSelectedCounterParty(
                      e.target.checked ? beneficiary.id : null,
                    );
                    setState(3);
                  }}
                />
              ) : (
                <div className="h-4 w-4" />
              )}

              <div className="flex flex-col min-w-0">
                <span className="font-medium text-gray-900 text-sm mb-1 truncate">
                  {beneficiary.fullName}
                </span>
                <div className="flex flex-col space-y-1.5">
                  <StatusTag status={beneficiary.verificationStatus} />
                  <span className="text-xs text-gray-500 truncate">
                    {beneficiary.accountNumber} | {beneficiary.bankName}
                  </span>
                </div>
              </div>
            </div>

            {beneficiary.verificationStatus !== 'passed' && (
              <Button
                label="Verify"
                onClick={() => {
                  setSelectedForVerification(beneficiary.id);
                  openVerificationModal();
                }}
                extraClasses="ml-4 h-7 px-3 text-xs font-medium min-w-[70px]"
              />
            )}
          </div>
        ))}
      </div>

      <div className="mt-6 flex justify-center">
        <Button
          onClick={() => setState(3)}
          label="Create Beneficiary"
          extraClasses="w-full max-w-xs py-2.5"
          loading={isLoading}
        />
      </div>

      <CounterPartyVerificationModal
        isOpen={isVerificationModalOpen}
        onClose={closeVerificationModal}
        counterId={selectedForVerification!}
        onVerificationSuccess={handleVerificationSuccess}
      />
    </div>
  );
};

export default Beneficiaries;
