import React from 'react';
import { Button } from 'components/common/Form';
import {
  CategoryIcon,
  DocumentCopyIcon,
  KeyIcon,
  WalletMinus,
} from 'components/icons';
import Atm from 'components/cards/AtmCard';
import { useAppSelector } from 'store/hooks';
import Fade from 'components/carousel';
import Modal from 'components/Modal';
import useDisclosure from 'hooks/useDisclosure';
import { executeFreezeCard } from 'api/wallet';
import { useToast } from 'components/Notifications/Toast';

const Carousel: React.FC = () => {
  const cards = useAppSelector((state) => state.accountStore.cards);
  const token = useAppSelector((state) => state.accountStore.tokenStore);
  const { showToast } = useToast();
  const {
    isOpen: isModalOpen,
    onOpen: openModal,
    onClose: closeModal,
  } = useDisclosure();

  const {
    isOpen: isLoading,
    onOpen: openLoading,
    onClose: closeLoading,
  } = useDisclosure();

  const handleBlockCard = async () => {
    try {
      openLoading();
      const result = await executeFreezeCard(
        cards[0]?.id,
        token,
        cards[0]?.is_blocked,
      );
      if (!result.isSuccess) throw new Error(result.message);
      showToast(result.message, 'success');
      window.location.reload();
    } catch (error: any) {
      showToast(error.message, 'error');
    } finally {
      closeModal();
      closeLoading();
    }
  };

  // Copy data to clipboard
  const handleCopy = (data: string) => {
    navigator.clipboard.writeText(data).then(
      () => {
        showToast('Copied to clipboard!', 'success');
      },
      (err) => {
        showToast('Failed to copy', 'error');
        console.error('Failed to copy: ', err);
      },
    );
  };

  const carousels = Object.values(cards!).map((item: any) => ({
    id: 3,
    component: <Atm wallet={item} />,
  }));

  const cardItems = [
    {
      name: 'Card Digits',
      icon: WalletMinus,
      data: cards[0]?.virtual_bin_number,
    },
    {
      name: 'CVV',
      icon: KeyIcon,
      data: cards[0]?.virtual_cvv_number,
    },
    {
      name: 'Valid Date',
      icon: CategoryIcon,
      data: cards[0]?.virtual_valid_date,
    },
  ];

  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-col items-center w-[374px] pt-6 pb-11 px-6 shadow-lg bg-white">
        <div className="w-full">
          <div className="flex flex-col mb-12">
            {/* Ensure the Fade component fits within its container */}
            <Fade items={carousels} />
          </div>
        </div>
        <h2 className="text-lg font-semibold mb-4">Take Note</h2>
        <p className="text-center text-[#585757] font-poppins font-normal text-[14px] leading-[21px]">
          You should not share your card details with anyone. Toggle the eye to
          see your digits.
        </p>
        <div className="w-full">
          {cardItems.map((item, idx) => (
            <div
              key={idx}
              className="flex p-4 justify-between border-b-2 border-[#E8E8E8]"
            >
              <div className="flex gap-4">
                <item.icon color="red" />
                {item.name}
              </div>
              <div
                className="cursor-pointer"
                onClick={() => handleCopy(item.data)}
              >
                <DocumentCopyIcon />
              </div>
            </div>
          ))}
        </div>
        <div className="flex flex-row w-full items-center justify-center gap-6">
          <Button
            extraClasses={`${
              cards[0]?.is_blocked ? 'bg-green-600' : 'bg-red-600'
            }  text-white px-4 py-2 rounded-md`}
            label={`${cards[0]?.is_blocked ? 'Unblock Card' : 'Block Card'} `}
            onClick={openModal}
          />
          <Button outline={true} label="Shuffle Digits" />
        </div>
      </div>

      <Modal
        closeModal={closeModal}
        isOpen={isModalOpen}
        title={`${cards[0]?.is_blocked ? 'Unblock Card' : 'Block Card'}`}
        width={'max-w-lg'}
      >
        <div className="text-center">
          <p className="text-md font-medium mb-4">
            Are you sure you want to block this card? This action cannot be
            undone.
          </p>
          <div className="flex justify-center gap-4">
            <Button
              extraClasses={`${
                cards[0]?.is_blocked
                  ? 'bg-[primary] text-white px-4 py-2 rounded-md'
                  : 'bg-red-600 text-white px-4 py-2 rounded-md'
              }`}
              label={`${cards[0]?.is_blocked ? 'Yes, Unblock' : 'Yes, Block'}`}
              loading={isLoading}
              onClick={handleBlockCard}
            />
            <Button
              outline={true}
              extraClasses="text-gray-600 border-gray-300 px-4 py-2 rounded-md"
              label="Cancel"
              onClick={closeModal}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Carousel;
