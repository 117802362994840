import React, { lazy, Suspense } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import type { Settings } from 'react-slick';

// Lazy load the Slider component
const SlickSlider = lazy(() =>
  import('react-slick').then((module) => ({
    default: module.default as unknown as React.ComponentType<Settings>,
  })),
);

interface Props {
  items: { id: number; component: JSX.Element }[];
  onSlideChange?: (index: number) => void;
}

const Fade: React.FC<Props> = ({ items, onSlideChange }) => {
  const settings: Settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    afterChange: onSlideChange,
  };

  return (
    <div className="slider-container">
      <Suspense fallback={<div>Loading...</div>}>
        <SlickSlider {...settings}>
          {items.map((item) => (
            <div key={item.id}>{item.component}</div>
          ))}
        </SlickSlider>
      </Suspense>
    </div>
  );
};

export default Fade;
