import { Button, Input, Select } from 'components/common/Form';
import authBg from 'assets/q1.png';
import { Controller, useForm } from 'react-hook-form';
import { paymentFormInputs } from 'utils/data';
import KycLayout from 'layouts/Kyc';
import useDisclosure from 'hooks/useDisclosure';
import { useToast } from 'components/Notifications/Toast';
import { useAppSelector } from 'store/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatDateForInput, getSlug } from 'utils/helpers';
import { executeCreateVirtualCard } from 'api/kyc';

import { AccountData } from 'types/data';
import React from 'react';
import { BiChevronDown } from 'react-icons/bi';

const CreateVirtualCard = () => {
  const {
    trigger,
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<UserCardRegistrationPayload>({
    mode: 'onSubmit',
  });

  const token = useAppSelector((state) => state.accountStore.tokenStore);
  const user = useAppSelector((state) => state.accountStore.account);
  const currency = new URLSearchParams(useLocation().search).get('currency');
  const wallets = useAppSelector((state) => state.accountStore.wallets);

  const navigate = useNavigate();
  const {
    isOpen: isLoading,
    onOpen: openLoading,
    onClose: closeLoading,
  } = useDisclosure();

  const { showToast } = useToast();

  const handleCreateVirtualCard = async (data: any) => {
    if (!(await trigger())) return false;
    if (!user?.id) return;

    console.log({ data });

    try {
      openLoading();
      const payload: any = {
        customerEmail: user.email,
        firstName: user.first_name,
        lastName: user.last_name,
        currency,
        walletId: data.wallet.id,
      };

      const result = await executeCreateVirtualCard(payload, token);
      if (!result.isSuccess) throw new Error(result.message);
      showToast(result.message, 'success');
      navigate('/dashboard');
    } catch (error: any) {
      showToast(error.message, 'error');
    } finally {
      closeLoading();
    }
  };

  return (
    <KycLayout background={true} backgroundImage={authBg}>
      <h2 className="text-2xl font-semibold mb-[35px]">Create Virtual Card</h2>
      <form
        className="space-y-6"
        onSubmit={handleSubmit(handleCreateVirtualCard)}
      >
        {paymentFormInputs(Object.values(wallets)).map((input, idx) => {
          return input.type === 'select' ? (
            <div className="flex w-full flex-col gap-3 mb-4" key={idx}>
              <p className="text-black text-sm">{input.label}</p>
              <Controller
                name={input.name as keyof UserCardRegistrationPayload} // Ensure this matches the correct payload type
                control={control}
                render={({ field }) => (
                  <Select
                    selected={
                      field.value as unknown as { id: number; name: string }
                    }
                    setSelected={(value) => field.onChange(value)}
                    datas={input.data as any}
                    required={input.isRequired}
                  >
                    <div className="w-full flex-btn text-textGray text-sm p-4 border border-border font-light rounded-lg focus:border focus:border-subMain">
                      {(field.value as unknown as { id: number; name: string })
                        ?.name || ''}
                      <BiChevronDown className="text-xl" />
                    </div>
                  </Select>
                )}
              />
              {errors[input.name as keyof UserCardRegistrationPayload] && (
                <p className="text-red-500 text-xs mt-1">
                  {
                    errors[input.name as keyof UserCardRegistrationPayload]
                      ?.message as string
                  }
                </p>
              )}
            </div>
          ) : (
            <Input
              key={idx}
              name={getSlug(input.name)}
              placeholder={input.placeholder}
              color={true}
              label={input.label}
              type={input.type}
              register={register}
              defaultValue={
                input.name === 'dob' && user?.[input.name as keyof AccountData]
                  ? formatDateForInput(
                      String(user[input.name as keyof AccountData]),
                    )
                  : String(user?.[input.name as keyof AccountData] ?? '')
              }
            />
          );
        })}

        <Button
          label="Continue"
          loading={isLoading}
          extraClasses="mt-[196px] mb-[24px]"
        />
      </form>
    </KycLayout>
  );
};

export default CreateVirtualCard;
