import React, { useState } from 'react';
import DashboardLayout from 'layouts/Dashboard';
import { profileInputs } from 'utils/data';
import { Button, Input } from 'components/common/Form';
import { useForm } from 'react-hook-form';
import { useAppSelector } from 'store/hooks';
import { AccountData } from 'types/data';
import useDisclosure from 'hooks/useDisclosure';
import { executeUpdateProfile } from 'api/user';
import { useToast } from 'components/Notifications/Toast';
import UploadInput from 'pages/kyc/Upload';

interface KycUploads {
  photo?: string;
  image?: string;
  id_type: string;
  [key: string]: string | undefined;
}

interface ExtendedAccountData extends AccountData {
  photo: File;
}

const Index = () => {
  const user = useAppSelector((state) => state.accountStore.account);
  const token = useAppSelector((state) => state.accountStore.tokenStore);
  const {
    control,
    // trigger,
    register,
    setValue,
    setError,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<ExtendedAccountData>({
    mode: 'onSubmit',
  });

  const [imageUrls, setImageUrls] = useState<KycUploads | undefined>({
    id_type: '',
  });

  const {
    isOpen: isLoading,
    onOpen: openLoading,
    onClose: closeLoading,
  } = useDisclosure();

  const { showToast } = useToast();

  const onSubmit = async (data: ExtendedAccountData) => {
    try {
      openLoading();
      console.log({ data });

      const updateData = {
        // ...data,
        profile_image: imageUrls?.profile_image || user?.profile_image,
      };

      const result = await executeUpdateProfile(updateData, token);
      if (!result.isSuccess) throw new Error(result.message);
      showToast(result.message, 'success');
    } catch (error: any) {
      showToast(error.message, 'error');
    } finally {
      closeLoading();
    }
  };

  const handleSetValue = (name: string, value: File) => {
    setValue('photo', value);
  };

  const handleSetError = (name: string, value: any) => {
    setError('photo', value);
  };

  const photoFile = watch('photo');

  return (
    <DashboardLayout title={''}>
      <div className="max-w-3xl mx-auto px-4 py-8">
        {/* Header Section */}
        <div className="mb-8">
          <h1 className="text-2xl font-semibold mb-2 text-center">Profile</h1>
          <p className="text-gray-600 text-sm text-center">
            Manage your personal information, and control which information
            other people see and apps may access.
          </p>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          {/* Profile Photo Section */}
          <div className="flex flex-col items-center mb-8">
            <div className="relative flex flex-col items-center">
              <div className="w-32 h-32 rounded-full overflow-hidden bg-gray-100 border-4 border-white shadow-lg mb-2">
                {imageUrls?.photo || user?.profile_image ? (
                  <img
                    src={imageUrls?.photo || user?.profile_image}
                    alt="Profile"
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <div className="w-full h-full flex items-center justify-center bg-blue-50">
                    <span className="text-3xl font-semibold text-blue-500">
                      {user?.first_name?.charAt(0)}
                      {user?.last_name?.charAt(0)}
                    </span>
                  </div>
                )}
              </div>

              <div className="w-auto">
                <UploadInput
                  register={register}
                  name="profile_image"
                  label=""
                  setImageUrls={setImageUrls}
                  setValue={handleSetValue}
                  setError={handleSetError}
                  error={errors?.photo?.message}
                  value={photoFile}
                  accept=".jpg,.jpeg,.png"
                  maxFileSize={5}
                  // className="text-xs text-blue-500 hover:text-blue-600 cursor-pointer"
                />
              </div>
            </div>

            <div className="mt-4 text-center">
              <h2 className="text-xl font-semibold text-gray-800">
                {user?.first_name} {user?.last_name}
              </h2>
              <p className="text-sm text-gray-500 mt-1">USERID: {user?.id}</p>
            </div>
          </div>

          {/* Profile Form Section */}
          <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {profileInputs().map((input, idx) => (
                <div key={idx}>
                  <Input
                    control={control}
                    type={input.type}
                    color={true}
                    name={input.name as keyof AccountData}
                    placeholder={input.placeholder}
                    label={input.label}
                    register={register}
                    defaultValue={String(
                      user?.[input.name as keyof AccountData] ?? '',
                    )}
                    required={true}
                  />
                  {errors[input.name as keyof AccountData] && (
                    <p className="text-red-500 text-xs mt-1">
                      {errors[
                        input.name as keyof AccountData
                      ]?.message?.toString()}
                    </p>
                  )}
                </div>
              ))}
            </div>

            <div className="flex justify-end pt-6">
              <Button
                label="Update Profile"
                loading={isLoading}
                type="submit"
              />
            </div>
          </form>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Index;
