const AboutUs = () => {
  const supportedCountries = [
    'Nigeria',
    'Ghana',
    'Kenya',
    'Rwanda',
    'Benin',
    'Ivory Coast',
    'Burkina Faso',
    'Mali',
    'Cameroon',
    'Uganda',
    'Togo',
    'Senegal',
    'Guinea Conakry',
  ];

  return (
    <div>
      {/* About Us Section */}
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">About Us</h2>
        <p className="text-gray-700 leading-relaxed">
          WorldHero Pay is an innovative subsidiary of WorldHero LLC, a
          registered entity in both the United States and Nigeria. Our mission
          is to revolutionize global financial accessibility and convenience by
          delivering cutting-edge solutions that meet the dynamic needs of
          today&rdquo;s digital economy.
        </p>
      </section>

      {/* Vision Section */}
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Vision</h2>
        <p className="text-gray-700 leading-relaxed">
          At WorldHero Pay, our vision is to transform the banking landscape by
          embedding advanced technology into every facet of financial
          management. We strive to empower users worldwide by providing a
          secure, intuitive, and inclusive mobile app experience that eliminates
          traditional banking barriers and makes financial management
          effortless.
        </p>
      </section>

      {/* Mission Section */}
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Mission</h2>
        <p className="text-gray-700 leading-relaxed">
          To redefine cross-border financial services by providing seamless fund
          management, payments, and international money transfers through a
          single, intuitive app.
        </p>
      </section>

      {/* Supported Countries Section */}
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Supported Countries</h2>
        <p className="text-gray-700 leading-relaxed mb-4">
          WorldHero Pay facilitates seamless payments across borders with zero
          exchange rate fees for users in the USA, Canada, and the UK. For
          currency exchange, the app supports transactions in 13 African
          countries, including:
        </p>
        <ul className="list-none grid grid-cols-1 md:grid-cols-1 gap-2">
          {supportedCountries.map((country, index) => (
            <li key={index} className="text-gray-700">
              • {country}
            </li>
          ))}
        </ul>
      </section>

      {/* Compliance Notice */}
      <section className="mt-8 border-t pt-6">
        <p className="text-gray-600 text-sm italic">
          All services are delivered in full compliance with internationally
          recognized regulations and local government policies.
        </p>
      </section>
    </div>
  );
};

export default AboutUs;
