import { Input } from 'components/common/Form';
import { UseFormRegister, FieldErrors, UseFormWatch } from 'react-hook-form';
import { registrationInputsThree } from 'utils/data';
import closeIcon from 'assets/vector.png';
import { useState, useEffect } from 'react';

interface DeviceInfo {
  channel: string;
  device_identifier: string;
  device_os: string;
  device_model: string;
  ip_address: string;
}

interface RegistrationData extends DeviceInfo {
  first_name: string;
  last_name: string;
  middle_name: string;
  phone_number: string;
  country_id: string;
  email: string;
  membership_type: string;
  dob: string;
  bvn: string;
  password: string;
  confirm_password: string;
  country_code: string;
}
interface FormProps {
  register: UseFormRegister<RegistrationData>;
  errors: FieldErrors<RegistrationData>;
  watch: UseFormWatch<RegistrationData>;
}

const Third: React.FC<FormProps> = ({ watch, register, errors }) => {
  const password = watch('password');
  const [isPasswordStrong, setIsPasswordStrong] = useState(false);

  const checkPasswordStrength = () => {
    const requirements = [
      { label: '1 Small Letter', regex: /[a-z]/ },
      { label: '1 Capital Letter', regex: /[A-Z]/ },
      { label: '1 Number', regex: /[0-9]/ },
      {
        label: '1 Special Character',
        regex: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/,
      },
      { label: '8 Characters', regex: /.{8,}/ },
    ];

    if (!password) return [];
    return requirements.map((req) => ({
      label: req.label,
      met: req.regex.test(password),
    }));
  };

  console.log(isPasswordStrong);

  useEffect(() => {
    const requirements = checkPasswordStrength();
    const allRequirementsMet = requirements.every((req) => req.met);
    setIsPasswordStrong(allRequirementsMet);
  }, [password]);

  return (
    <>
      <h2 className="text-2xl font-semibold mb-[35px]">Secure your Account</h2>
      {registrationInputsThree().map((input, idx) => (
        <div key={idx} className="mb-4">
          <Input
            name={input.name}
            placeholder={input.placeholder}
            color={true}
            label={input.label}
            type={input.type}
            register={register}
          />
          {errors[input.name as keyof RegistrationData] && (
            <p className="text-red-600 text-sm mt-1">
              {errors[input.name as keyof RegistrationData]?.message ||
                `Enter a valid ${input.label} is required`}
            </p>
          )}
        </div>
      ))}

      <div className="grid grid-cols-2 gap-y-1.25">
        {checkPasswordStrength()?.map(
          (req, idx) =>
            !req.met && (
              <div key={idx} className="flex items-center">
                <img src={closeIcon} alt="Close Icon" />
                <p className="text-sm text-gray-400 ml-1.5">{req.label}</p>
              </div>
            ),
        )}
      </div>
    </>
  );
};

export default Third;
