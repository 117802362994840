import React from 'react';
import { Link } from 'react-router-dom';
import AuthLayout from 'layouts/Auth';
import { useForm } from 'react-hook-form';
import { validateEmailInput } from 'utils/data';
import FlagsSelect from 'components/common/FlagSelect';
import { useToast } from 'components/Notifications/Toast';
import { Button, FormFooter, Input } from 'components/common/Form';
import { BiLogInCircle } from 'react-icons/bi';
import { useAppContext } from 'contexts/App';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import useDisclosure from 'hooks/useDisclosure';
import { executeValidateEmail } from 'api/auth';
import { STEPS, updateLevel } from 'store/registration/registerSlice';

interface RegistrationData {
  email: string;
}

interface ValidateEmailProps {
  setActiveStep: (no: any) => void;
  activeStep: number;
}

const ValidateEmail: React.FC<ValidateEmailProps> = ({ setActiveStep }) => {
  const {
    trigger,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<RegistrationData>({
    mode: 'onChange',
  });

  const { isLoadingData } = useAppContext();
  const { selectedCountry } = useAppSelector((state) => state.countryStore);
  const { showToast } = useToast();
  const {
    isOpen: isLoading,
    onOpen: openLoading,
    onClose: closeLoading,
  } = useDisclosure();

  const dispatch = useAppDispatch();

  const handleValidateEmail = async (data: RegistrationData) => {
    if (!(await trigger())) return false;
    console.log(errors['email']);
    try {
      openLoading();
      const payload: VerificationType = {
        email: data.email,
        type: 'email-verification',
        country_id: selectedCountry?.id,
      };
      const result = await executeValidateEmail(payload);
      if (!result.isSuccess) throw new Error(result.message);
      dispatch(
        updateLevel({
          step: STEPS.VERIFY_EMAIL,
          data: {
            EMAIL: data.email,
          },
        }),
      );
      setActiveStep((prev: any) => prev + 1);
    } catch (error: any) {
      showToast(error.message, 'error');
    } finally {
      closeLoading();
    }
  };

  return (
    <AuthLayout backgroundOverlay="bg-gray-200">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-semibold">Sign Up</h2>
        <FlagsSelect />
      </div>
      <div className="space-y-6 mt-12">
        <form
          className="w-[375px] rounded-2xl mx-auto"
          onSubmit={handleSubmit(handleValidateEmail)}
        >
          {isLoadingData
            ? new Array(1)
                .fill('-')
                .map((_, index) => (
                  <div
                    className="max-w-[500px] w-full rounded-md h-[131px] bg-gray-200 animate-pulse"
                    key={`loading-card-${index}`}
                  ></div>
                ))
            : validateEmailInput().map((input, idx) => (
                <React.Fragment key={idx}>
                  <Input
                    label={input.label}
                    type={input.type}
                    color={true}
                    placeholder={input.placeholder}
                    register={register}
                    required
                    name={input.name}
                    errors={errors}
                  />
                </React.Fragment>
              ))}
          <Button
            label="Continue"
            Icon={BiLogInCircle}
            loading={isLoading}
            extraClasses="mt-[196px] mb-[24px]"
          />

          {/* Terms and Privacy Links */}
          <div className="text-center text-sm text-gray-500 mb-6">
            By Signing up, you agree to our{' '}
            <Link
              to="/terms-of-service"
              className="text-blue-600 hover:underline"
            >
              Terms of Use
            </Link>{' '}
            and{' '}
            <Link
              to="/privacy-policy"
              className="text-blue-600 hover:underline"
            >
              Privacy Policy
            </Link>
          </div>

          <FormFooter />
        </form>
      </div>
    </AuthLayout>
  );
};

export default ValidateEmail;
