/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import ValidateEmail from './ValidateEmail';
import VerifyEmail from './VerifyEmail';
import Registration from './Registration';
import SecureWallet from './SecureWallet';
import Address from './Address';
import SecureTransactionsGuide from './SecureTransactionsGuide';
import SecureAccount from './SecureAccount';
import SecureAccountSuccessful from './SecureAccountSuccessful';
import { useAppSelector } from 'store/hooks';

const FacilityForm = () => {
  const { currentStep } = useAppSelector((state) => state.registerStore);

  console.log({ currentStep });

  const valueMap = {
    VALIDATE_EMAIL: 0,
    VERIFY_EMAIL: 1,
    SIGN_UP: 2,
    RESIDENTIAL_ADDRESS: 3,
    SECURE_TRANSACTIONS_GUIDE: 4,
    SECURE_ACCOUNT: 5,
    ACTIVATE_PIN: 6,
  };
  const [activeStep, setActiveStep] = useState(
    (valueMap as any)[currentStep] || 0,
  );

  const steps = [
    {
      name: 'Validate Email',
      component: (
        <ValidateEmail setActiveStep={setActiveStep} activeStep={activeStep} />
      ),
    },
    {
      name: 'Verify Email',
      component: <VerifyEmail setActiveStep={setActiveStep} />,
    },
    {
      name: 'Sign Up',
      component: <Registration setActiveStep={setActiveStep} />,
    },
    {
      name: 'Residential Address',
      component: <Address setActiveStep={setActiveStep} />,
    },
    {
      name: 'Secure Transactions Guide',
      component: <SecureTransactionsGuide setActiveStep={setActiveStep} />,
    },
    {
      name: 'Secure Account',
      component: <SecureAccount setActiveStep={setActiveStep} />,
    },
    {
      name: 'Activate PIN',
      component: <SecureAccountSuccessful />,
    },
    {
      name: 'Secure Wallet',
      component: <SecureWallet />,
    },
  ];
  return <> {steps[activeStep].component}</>;
};

export default FacilityForm;
